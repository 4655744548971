import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://fed5502ae17045c82af78d270daabbee@o4506910116347904.ingest.us.sentry.io/4507130368294912",
  debug: false,
  environment: "production",
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 0.05,
  sampleRate: 0.05,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  replaysSessionSampleRate: 0.05,
  replaysOnErrorSampleRate: 1,
});